'use client';

import { FC } from 'react';
import { useColorPicker } from 'react-best-gradient-color-picker';
import { Sender } from '@prisma/client';
import cn from 'classnames';
import CollectEmail from './collect-email.component';
import Avatar from '../avatar.component';
import { useMessengerContext } from '@/context/messengerContext';
import { getTextColor, addAlpha } from '@/utils/colors';
import useTranslationStore from '@/store/translation';

import AILogo from '@public/assets/images/ai-agent.png';

type SupportRequestedProps = {
  color: {
    userMessage: string;
    chatbotMessage: string;
  };
  showEmailForm?: boolean;
  aiAvatar?: string;
  position: 'left' | 'right';
};

const SupportRequested: FC<SupportRequestedProps> = ({
  color,
  showEmailForm = false,
  aiAvatar,
  position,
}) => {
  const { getValue } = useTranslationStore();

  return (
    <div className="flex flex-col w-full">
      <FakeMessage
        sender="AI"
        showEmailForm={showEmailForm}
        position={position}
        message={
          <>
            <h5 className="text-[14px] leading-5 font-medium">
              {getValue('supportRequestedTitle')}
            </h5>
            <p className="text-xs mt-[10px]">
              {getValue('supportRequestedDescription')}
            </p>
          </>
        }
        backgroundColor={color?.chatbotMessage}
        aiAvatar={aiAvatar}
      />
    </div>
  );
};

type StaticMessageProps = {
  sender: Sender;
  message: string | JSX.Element;
  backgroundColor: string;
  position: 'left' | 'right';
  showEmailForm?: boolean;
  aiAvatar?: string;
};
const FakeMessage: FC<StaticMessageProps> = ({
  sender,
  message,
  backgroundColor,
  position,
  showEmailForm = false,
  aiAvatar,
}) => {
  const { handleSubmitEmail } = useMessengerContext();

  const { valueToHex, isGradient } = useColorPicker(backgroundColor, () => {});
  const hexString = valueToHex();
  const textColor = getTextColor(hexString);
  const borderColor = isGradient ? '#E5EEF3' : addAlpha(textColor, 0.1);

  return (
    <>
      <div
        id="message-box"
        className={cn(
          'inline-flex flex-col mb-[15px] max-w-full sm:max-w-[95%]',
          {
            'ml-auto': position === 'right',
            'mr-auto': position === 'left',
          },
        )}
      >
        <div
          className={cn('inline-flex w-fit max-w-full', {
            'flex-row-reverse': position === 'right',
          })}
        >
          {sender === Sender.AI && (
            <div
              className={cn('w-[28px] h-[28px] mt-auto', {
                'ml-[10px]': position === 'right',
                'mr-[10px]': position === 'left',
              })}
            >
              <Avatar
                src={aiAvatar || AILogo.src}
                size={28}
                email=""
                defaultAIAvatar={!aiAvatar}
              />
            </div>
          )}

          <div className="w-fit max-w-full">
            {showEmailForm && (
              <CollectEmail
                className="!mt-0 mb-3"
                style={{ backgroundColor, textColor, borderColor }}
                handleSubmit={(value) => {
                  handleSubmitEmail(value);
                }}
              />
            )}

            <div
              id="message"
              style={{
                background:
                  backgroundColor ||
                  'linear-gradient(339.94deg, #F4FBFF 55.36%, rgba(244, 251, 255, 0) 98.66%)',
                color: textColor,
                border: `1px solid ${borderColor}`,
              }}
              className={cn(
                'py-3 px-[15px] rounded-lg text-sm w-fit break-words max-w max-w-full lg:max-w-[80%]',
                {
                  'ml-auto': position === 'right',
                  'mr-auto': position === 'left',
                },
              )}
            >
              {message}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportRequested;
