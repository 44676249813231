import cn from 'classnames';
import { FC, useMemo } from 'react';
import MessengerButton from './messenger-button.component';
import {
  messengerStore,
  useMessengerContext,
} from '@/context/messengerContext';
import { CustomizeData } from '@/app/(dashboard)/aibot/[chatbotId]/(layout)/customize/types';
import ChatIcon from '../icons/chat-icon.component';

type MessengerClosedBannerProps = {
  className?: string;
};
const MessengerClosedBanner: FC<MessengerClosedBannerProps> = ({
  className,
}) => {
  const { chatbot, updateActiveChat } = useMessengerContext();

  const [updateScreen] = messengerStore((state) => [state.updateScreen]);

  const customize = useMemo(
    () => chatbot?.customize as CustomizeData,
    [chatbot],
  );

  return (
    <div
      id="messenger-closed-banner"
      className={cn(
        'flex flex-col items-center justify-center bg-[#F5F5F5] py-[30px] px-3 sm:px-[15px] border-t border-b border-[#EAEAEA]',
        className,
      )}
    >
      <p className="text-[13px] sm:text-[14px] leading-none mb-[12px] text-center">
        The conversation has been closed by the agent.
      </p>
      <MessengerButton
        backgroundColor={customize?.theme?.color?.userMessage}
        onClick={() => {
          updateActiveChat(null);
          updateScreen('messenger');
        }}
      >
        New conversation
        <ChatIcon className="ml-[6px]" />
      </MessengerButton>
    </div>
  );
};

export default MessengerClosedBanner;
